import { Song } from "../models/song/song";

export function getNextOrPreviousSong(
  currentSongId: number,
  songs: Song[],
  next: "next" | "previous",
): Song | null {
  const songIndex = songs.findIndex((s) => s.id === currentSongId);

  if (songIndex === -1 && songs.length) {
    return songs[0];
  } else {
    if (next === "next") {
      const nextSong = songs[songIndex + 1];

      if (nextSong) {
        return nextSong;
      }
    } else {
      const previousSong = songs[songIndex - 1];

      if (previousSong) {
        return previousSong;
      }
    }
  }

  return null;
}
