import React, { useRef } from "react";
import { View } from "react-native";
import { CONTAINER } from "../../theme/view-style";
import { Text } from "../../components";
import { spacing } from "../../theme";
import {
  actions,
  RichEditor,
  RichToolbar,
} from "react-native-pell-rich-editor";
import { SongForm } from "./manage-song-screen";
import { BrandInput } from "../../components/brand-input/brand-input";

export function GuitaristForm(props: {
  state: SongForm;
  handleChange: (name, value) => void;
}) {
  const { state, handleChange } = props;
  const rich = useRef(null);

  return (
    <View style={{ ...CONTAINER }}>
      <BrandInput
        labelTx="manageSongScreen.tab"
        value={state.tab || undefined}
        keyboardType="url"
        onChangeText={(value) => handleChange("tab", value)}
      />

      <Text
        preset="fieldLabel"
        tx="manageSongScreen.guitar"
        style={{ marginTop: spacing[6], marginBottom: spacing[1] }}
      />
      <RichToolbar
        editor={rich}
        actions={[
          actions.keyboard,
          actions.setBold,
          actions.setItalic,
          actions.removeFormat,
          actions.insertLink,
        ]}
      />
      <RichEditor
        initialContentHTML={state.guitar}
        ref={rich}
        onChange={(value) => handleChange("guitar", value)}
        initialHeight={300}
      />
    </View>
  );
}
