import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { BrandScreen } from "../../components/brand-screen/brand-screen";
import { ScreenButton } from "../../components/screen-button/screen-button";
import { BrandInput } from "../../components/brand-input/brand-input";
import { useStores } from "../../models";
import { StackScreenProps } from "@react-navigation/stack";
import { IntroParamList } from "../../navigation";
import { formValidateField } from "../../services/form-validate/form-validate-field";
import { hasErrors } from "../../services/form-validate/has-errors";
import { showMessage } from "react-native-flash-message";
import { displayErrors } from "../../services/form-validate/display-errors";
import { View } from "react-native";
import { CONTAINER } from "../../theme/view-style";

interface UserForm {
  username: string;
  password: string;
  errors: any;
}

function getInitialState(): UserForm {
  return {
    username: "",
    password: "",
    errors: {},
  };
}

export const SignupScreen: FC<
  StackScreenProps<IntroParamList, "signup">
> = observer(function SignupScreen({ navigation }) {
  const { userStore } = useStores();
  const goBack = () => navigation.goBack();
  const [state, setState] = useState(getInitialState());

  function handleChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  async function signup() {
    const errors = Object.assign(
      {},
      ...["username", "password"].map((field) =>
        formValidateField(field, state[field]),
      ),
    );

    setState({
      ...state,
      errors,
    });

    if (hasErrors(errors)) {
      showMessage({
        message: displayErrors(errors).join("\n"),
        type: "danger",
      });
    } else {
      const result = await userStore.signup(state.username, state.password);

      if (result.kind === "ok") {
        goBack();
      }
    }
  }

  return (
    <BrandScreen>
      <View style={CONTAINER}>
        <BrandInput
          labelTx="form.username"
          value={state.username}
          autoCapitalize="none"
          onChangeText={(value) => handleChange("username", value)}
          autoCorrect={false}
        />

        <BrandInput
          labelTx="form.password"
          value={state.password}
          autoCapitalize="none"
          secureTextEntry={true}
          onChangeText={(value) => handleChange("password", value)}
          autoCorrect={false}
        />
      </View>

      <ScreenButton tx="form.signup" onPress={() => signup()} />
    </BrandScreen>
  );
});
