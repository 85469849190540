import { palette } from "./palette";

/**
 * Roles for colors.  Prefer using these over the palette.  It makes it easier
 * to change things.
 *
 * The only roles we need to place in here are the ones that span through the app.
 *
 * If you have a specific use-case, like a spinner color.  It makes more sense to
 * put that in the <Spinner /> component.
 */
export const color = {
  palette,
  transparent: "rgba(0, 0, 0, 0)",
  transparentLight: "rgba(255, 255, 255, 0.3)",
  transparentDark: "rgba(255, 255, 255, 0.1)",
  transparentBlackLighter: "rgba(0, 0, 0, 0.1)",
  transparentBlackLight: "rgba(0, 0, 0, 0.2)",
  transparentBlackDark: "rgba(0, 0, 0, 0.8)",
  background: palette.black,
  backgroundLighter: palette.blackLight,
  primary: palette.orange,
  primaryDarker: palette.orangeDarker,
  line: palette.offWhite,
  text: palette.white,
  darkText: palette.black,
  dim: palette.lightGrey,
  error: palette.angry,
  button: palette.white,

  /**
   * Storybook background for Text stories, or any stories where
   * the text color is color.text, which is white by default, and does not show
   * in Stories against the default white background
   */
  storybookDarkBg: palette.black,

  /**
   * Storybook text color for stories that display Text components against the
   * white background
   */
  storybookTextColor: palette.black,
};
