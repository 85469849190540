/**
 * This is the navigator you will modify to display the logged-in screens of your app.
 * You can use RootNavigator to also display an auth flow or other user flows.
 *
 * You'll likely spend most of your time in this file.
 */
import React, { useEffect } from "react";
import {
  createStackNavigator,
  StackHeaderTitleProps,
} from "@react-navigation/stack";
import { SignupScreen, WelcomeScreen } from "../screens";
import { LoginScreen } from "../screens/login/login-screen";
import { BandCreateScreen } from "../screens/band/band-create-screen";
import { NavigatorScreenParams } from "@react-navigation/native";
import { SongsScreen } from "../screens/songs/songs-screen";
import {
  ManageSongScreen,
  SongForm,
} from "../screens/songs/manage-song-screen";
import { useStores } from "../models";
import { MenuProvider } from "react-native-popup-menu";
import {
  ManageSetlistScreen,
  SetlistForm,
} from "../screens/setlists/manage-setlist-screen";
import { SetlistsScreen } from "../screens/setlists/setlists-screen";
import { SetlistScreen } from "../screens/setlists/setlist-screen";
import FlashMessage from "react-native-flash-message";
import { BandUpdateScreen } from "../screens/band/band-update-screen";
import { BandJoinScreen } from "../screens/band/band-join-screen";
import { observer } from "mobx-react-lite";
import { color } from "../theme";
import { translate } from "../i18n";
import { StackHeaderOptions } from "@react-navigation/stack/src/types";
import { Text } from "../components/text/text";
import { TextStyle } from "react-native";
import { useStoreReviewIsAvailable } from "@use-expo/store-review";
import * as StoreReview from "expo-store-review";

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type IntroParamList = {
  welcome: undefined;
  login: undefined;
  signup: undefined;
  "band-create": undefined;
};

const headerOptions: StackHeaderOptions = {
  headerStyle: {
    backgroundColor: color.palette.darkBlack,
    shadowColor: "transparent",
    borderBottomWidth: 0,
  },
  headerTintColor: color.text,
  headerTitleStyle: {
    fontWeight: "bold",
  },
};

const IntroStack = createStackNavigator<IntroParamList>();

const IntroNavigator = observer(function IntroNavigator() {
  return (
    <IntroStack.Navigator
      screenOptions={{
        ...headerOptions,
      }}
      initialRouteName="welcome"
    >
      <IntroStack.Screen
        name="welcome"
        options={{
          title: translate("menu.home"),
          headerShown: false,
        }}
        component={WelcomeScreen}
      />
      <IntroStack.Screen
        name="login"
        options={{
          title: translate("loginScreen.header"),
        }}
        component={LoginScreen}
      />
      <IntroStack.Screen
        name="signup"
        options={{
          title: translate("signupScreen.header"),
        }}
        component={SignupScreen}
      />
      <IntroStack.Screen
        name="band-create"
        options={{
          title: translate("bandCreateScreen.header"),
        }}
        component={BandCreateScreen}
      />
    </IntroStack.Navigator>
  );
});

export type BandParamList = {
  songs: undefined;
  "song-update": { song: SongForm };
  "song-create": undefined;

  setlists: undefined;
  setlist: { setlistId: number };
  "setlist-update": { setlist: SetlistForm };
  "setlist-create": undefined;

  settings: undefined;
  "band-join": {
    inviteToken?: string;
  };
};

const BandStack = createStackNavigator<BandParamList>();

const BandNavigator = function () {
  return (
    <BandStack.Navigator
      screenOptions={{
        ...headerOptions,
        headerTitle({
          style,
          children: title,
        }: StackHeaderTitleProps & { style: TextStyle }) {
          return (
            <Text style={[style, { textAlign: "center" }]} numberOfLines={2}>
              {title}
            </Text>
          );
        },
      }}
      initialRouteName="setlists"
    >
      <BandStack.Screen
        name="songs"
        options={{ headerBackTitle: " " }}
        component={SongsScreen}
      />
      <BandStack.Screen
        name="song-update"
        options={{
          title: translate("manageSongScreen.header-update"),
          headerBackTitle: " ",
        }}
        component={ManageSongScreen}
      />
      <BandStack.Screen
        name="song-create"
        options={{
          title: translate("manageSongScreen.header-create"),
          headerBackTitle: " ",
        }}
        component={ManageSongScreen}
      />

      <BandStack.Screen
        name="setlists"
        options={{
          title: translate("setlistsScreen.header"),
        }}
        component={SetlistsScreen}
      />
      <BandStack.Screen
        name="setlist"
        options={{ headerBackTitle: " " }}
        component={SetlistScreen}
      />
      <BandStack.Screen
        name="setlist-update"
        options={{
          title: translate("manageSetlistScreen.header-update"),
          headerBackTitle: " ",
        }}
        component={ManageSetlistScreen}
      />
      <BandStack.Screen
        name="setlist-create"
        options={{
          title: translate("manageSetlistScreen.header-create"),
          headerBackTitle: " ",
        }}
        component={ManageSetlistScreen}
      />

      <BandStack.Screen
        name="settings"
        options={{
          title: translate("bandUpdateScreen.header"),
        }}
        component={BandUpdateScreen}
      />
      <BandStack.Screen
        name="band-join"
        options={{
          title: translate("userSettingsScreen.header"),
        }}
        component={BandJoinScreen}
      />
    </BandStack.Navigator>
  );
};

export type PrimaryParamList = {
  intro: NavigatorScreenParams<IntroParamList>;
  band: NavigatorScreenParams<BandParamList>;
};

const Main = createStackNavigator<PrimaryParamList>();

export const MainNavigator = observer(function MainNavigator() {
  const { globalStore, userStore, eventStore } = useStores();
  const [isStoreReviewAvailable] = useStoreReviewIsAvailable();

  const accessToken = userStore.currentUser?.accessToken;

  useEffect(() => {
    globalStore.hasOpenedScreen();
  }, []);

  useEffect(() => {
    if (isStoreReviewAvailable && globalStore.isEnoughOpenings) {
      StoreReview.requestReview();
      globalStore.clear();
    }
  }, [isStoreReviewAvailable, globalStore.isEnoughOpenings]);

  useEffect(() => {
    if (userStore.isReadyToPlay && accessToken) {
      eventStore.init(accessToken);
      eventStore.fetchAll();
    }
  }, [accessToken]);

  return (
    <>
      <MenuProvider>
        <Main.Navigator
          initialRouteName="intro"
          screenOptions={{
            headerShown: false,
          }}
        >
          {userStore.isReadyToPlay ? (
            <Main.Screen name="band" component={BandNavigator} />
          ) : (
            <Main.Screen name="intro" component={IntroNavigator} />
          )}
        </Main.Navigator>
      </MenuProvider>
      <FlashMessage position="top" duration={4000} />
    </>
  );
});

/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = ["welcome"];
export const canExit = (routeName: string) => exitRoutes.includes(routeName);
