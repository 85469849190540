import { View } from "react-native";
import { TAG, TAG_TEXT } from "../../theme/view-style";
import { Text } from "../../components";
import React from "react";

interface BrandTagProps {
  value: string | null;
}

export function BrandTag({ value }: BrandTagProps) {
  return (
    <>
      {value ? (
        <View style={TAG}>
          <Text style={TAG_TEXT} text={value} />
        </View>
      ) : null}
    </>
  );
}
