import { applySnapshot, Instance, SnapshotOut, types } from "mobx-state-tree";
import { withEnvironment } from "../extensions/with-environment";

export const GlobalStoreModel = types
  .model("GlobalStore")
  .props({
    numberOfOpenings: types.optional(types.number, 0),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get isEnoughOpenings() {
      return self.numberOfOpenings > 15;
    },
  }))
  .actions((self) => ({
    clear: function () {
      applySnapshot(self, {});
    },
    hasOpenedScreen() {
      self.numberOfOpenings = self.numberOfOpenings + 1;
    },
  }));

type GlobalStoreType = Instance<typeof GlobalStoreModel>;

export interface GlobalStore extends GlobalStoreType {}

type GlobalStoreSnapshotType = SnapshotOut<typeof GlobalStoreModel>;

export interface GlobalStoreSnapshot extends GlobalStoreSnapshotType {}

export const createGlobalStoreDefaultModel = () =>
  types.optional(GlobalStoreModel, {});
