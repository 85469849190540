import { Song } from "../models/song/song";
import { getDuration } from "../screens/setlists/get-total-duration";
import { Profile } from "../screens/setlists/profile";

export function getSongHtml(
  currentSong: Song | null,
  profile: Profile,
  mode: "normal" | "print" = "normal",
) {
  if (!currentSong) {
    return "";
  }

  let content = "";
  if (profile === Profile.GUITAR) {
    content = currentSong.guitar;
  }

  if (!content) {
    content = currentSong.lyric;
  }

  return `
<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <style>
      * {
          color: ${mode === "normal" ? "white" : "black"} !important;
      }

      body {
        padding: ${mode === "normal" ? 0 : "30px"};
        padding-top: ${mode === "normal" ? 0 : "10px"};
        margin: 0;
        margin-bottom: 250px;
      }

      #lyric {
          font-size: 1.5rem;
          font-family: Helvetica, arial, sans-serif;
      }

      #lyric-title {
          margin-top: 0;
          text-align: center;
          margin-bottom: 0;
      }

      #lyric-info {
          font-size: 0.8rem;
          margin-bottom: 1rem;
          display: inline-block;
          text-align: center;
          width: 100%;
      }

      .lyric-tag {
          background-color: white;
          color: black !important;
          padding: 3px 8px;
          border-radius: 5px;
          font-weight: bold;
      }

      .lyric-tag--dark {
          color: white !important;
          background-color: #888;
      }

      #lyric-content {
          column-width: 12em;
      }

      br {
          content: "";
          margin: 2em;
          display: block;
          font-size: 20%;
      }
  </style>
</head>
<body>
<div id="lyric">
<h2 id="lyric-title">${currentSong.title}</h2>
<small id="lyric-info">
    ${getDuration([currentSong])}${
    currentSong.key
      ? " / <span class='lyric-tag'>" + currentSong.key + "</span>"
      : ""
  }${
    currentSong.tags
      ? "<br /><br />" +
        currentSong.tags
          .split(",")
          .map(
            (tag) =>
              "<span class='lyric-tag lyric-tag--dark'>" + tag + "</span>",
          )
          .join(" ")
      : ""
  }
  </small>

<div id="lyric-content">
  ${content}
</div>
</div>
</body>
</html>`;
}
