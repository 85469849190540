import React from "react";
import { TouchableOpacity, View } from "react-native";
import { CONTAINER, INPUT } from "../../theme/view-style";
import { Text } from "../../components";
import { spacing } from "../../theme";
import { SongForm } from "./manage-song-screen";
import { BrandInput } from "../../components/brand-input/brand-input";
import { TextInputMask } from "react-native-masked-text";
import { translate } from "../../i18n";
import { compact } from "lodash";
import { BrandTag } from "./brand-tag";
import Tags from "react-native-tags";

export function MainForm(props: {
  state: SongForm;
  handleChange: (name, value) => void;
}) {
  const { state, handleChange } = props;

  return (
    <View style={{ ...CONTAINER }}>
      <BrandInput
        labelTx="manageSongScreen.title"
        value={state.title}
        onChangeText={(value) => handleChange("title", value)}
      />

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View style={{ marginRight: spacing[5] }}>
          <Text preset="fieldLabel" tx="manageSongScreen.duration" />
          <TextInputMask
            type="custom"
            placeholder="03:20"
            value={state.durationStr}
            options={{
              mask: "09:99",
            }}
            onChangeText={(text) => handleChange("durationStr", text)}
            style={INPUT}
          />
        </View>

        <View>
          <BrandInput
            labelTx="manageSongScreen.key"
            value={state.key || undefined}
            onChangeText={(value) => handleChange("key", value)}
          />
        </View>
      </View>

      <Text preset="fieldLabel" tx="manageSongScreen.tags" />
      <Tags
        textInputProps={{
          placeholder: translate("manageSongScreen.tags"),
        }}
        initialText=""
        initialTags={state.tags ? state.tags.split(",") : []}
        onChangeTags={(tags: string[]) =>
          handleChange("tags", compact(tags).join(","))
        }
        inputStyle={[INPUT, { marginTop: 0 }]}
        renderTag={({ tag, index, onPress }) => (
          <TouchableOpacity key={`${tag}-${index}`} onPress={onPress}>
            <BrandTag value={tag} />
          </TouchableOpacity>
        )}
      />
    </View>
  );
}
