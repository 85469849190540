import { FlatList, Platform, TouchableOpacity, View } from "react-native";
import WebView from "react-native-webview";
import { color, spacing } from "../../theme";
import { Text } from "../../components";
import { BOLD, CONTAINER, SONG } from "../../theme/view-style";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Song } from "../../models/song/song";
import { WallpaperPrimary } from "../../components/wallpaper/wallpaper-primary";
import { getDuration } from "./get-total-duration";
import { useStores } from "../../models";
import { observer } from "mobx-react-lite";
import { useKeepAwake } from "expo-keep-awake";
import BottomSheet from "@gorhom/bottom-sheet";
import Ionicons from "@expo/vector-icons/Ionicons";
import { palette } from "../../theme/palette";
import { getNextOrPreviousSong } from "../../services/get-next-or-previous-song";
import TextTicker from "react-native-text-ticker";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { FloatingAction } from "react-native-floating-action";
import { translate } from "../../i18n";
import { Profile } from "./profile";
import { BrandButton } from "../../components/screen-button/screen-button";
import { getSongHtml } from "../../services/get-song-html";
import * as Linking from "expo-linking";

interface SetlistProps {
  name: string;
  songs: Song[];
  onTitleChange: (title: string) => void;
}

const SECOND_BREAKPOINT = 300;

export const Setlist = observer(function Setlist(props: SetlistProps) {
  useKeepAwake();

  const { onTitleChange } = props;
  const { eventStore } = useStores();
  const songId = eventStore.songId;
  const [profile, setProfile] = useState<Profile>(Profile.SINGER);
  const songsToPlayRef = useRef<FlatList>(null);

  const bottomSheetRef = useRef<BottomSheet>(null);
  const snapPoints = useMemo(() => [90, SECOND_BREAKPOINT, "70%"], []);

  const currentSong: Song | null = getSong(songId, props.songs);
  const nextSong: Song | null = getNextOrPreviousSong(
    songId,
    props.songs,
    "next",
  );

  useEffect(() => {
    onTitleChange(`${props.name}\n${getDuration(props.songs)}`);
  }, [props.name]);

  useEffect(() => {
    if (Platform.OS === "web") {
      const handleArrows = function (e) {
        const LEFT_ARROW = 37;
        const RIGHT_ARROW = 39;

        if (e.keyCode === LEFT_ARROW) {
          pleasePlay(songId, props.songs, "previous");
        }

        if (e.keyCode === RIGHT_ARROW) {
          pleasePlay(songId, props.songs, "next");
        }
      };

      // @ts-ignore
      window.addEventListener("keydown", handleArrows);

      return () => {
        // @ts-ignore
        window.removeEventListener("keydown", handleArrows);
      };
    }
    return () => {};
  }, [songId]);

  function pleasePlay(
    currentSongId: number,
    songs: Song[],
    next: "next" | "previous",
  ) {
    const song = getNextOrPreviousSong(currentSongId, songs, next);

    if (song) {
      eventStore.sendSongToPlay(song.id);
    }
  }

  const renderProfile = useCallback(() => {
    switch (profile) {
      case Profile.SINGER:
        return (
          <WebView
            style={{
              backgroundColor: color.transparent,
            }}
            originWhitelist={["*"]}
            source={{
              html: getSongHtml(currentSong, Profile.SINGER),
            }}
          />
        );
      case Profile.GUITAR:
        return (
          <>
            <WebView
              style={{
                backgroundColor: color.transparent,
              }}
              originWhitelist={["*"]}
              source={{
                html: getSongHtml(currentSong, Profile.GUITAR),
              }}
            />

            {currentSong?.tab ? (
              <BrandButton
                tx="setlist.open-tab"
                onPress={() => Linking.openURL(currentSong?.tab || "")}
              />
            ) : null}
          </>
        );
      default:
        return null;
    }
  }, [profile, currentSong]);

  return (
    <View
      style={
        Platform.OS === "web" ? { maxHeight: "100vh", flex: 1 } : { flex: 1 }
      }
    >
      <View
        style={{
          ...CONTAINER,
          flex: 1,
        }}
      >
        <View style={{ flex: 1, paddingBottom: 100 }}>{renderProfile()}</View>

        <BottomSheet
          ref={bottomSheetRef}
          snapPoints={snapPoints}
          backgroundComponent={WallpaperPrimary}
          detached={true}
          enableContentPanningGesture={false}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: spacing[3],
              marginTop: -10,
            }}
          >
            <TouchableOpacity
              style={{
                marginLeft: spacing[5],
                flex: 1,
              }}
              onPress={() => {
                pleasePlay(songId, props.songs, "previous");
              }}
            >
              <Ionicons
                name="caret-back-circle-outline"
                size={60}
                color={palette.white}
              />
            </TouchableOpacity>

            <View
              style={{
                alignItems: "center",
                flex: 4,
              }}
            >
              {nextSong && (
                <>
                  <Text tx="setlist.next-song" />
                  <TextTicker
                    style={{ fontSize: 24 }}
                    duration={5000}
                    loop
                    bounce
                    repeatSpacer={50}
                    marqueeDelay={2000}
                  >
                    <Text preset="bold" text={nextSong.title} />
                  </TextTicker>
                </>
              )}
            </View>

            <TouchableOpacity
              style={{
                marginRight: spacing[5],
                flex: 1,
                alignItems: "flex-end",
              }}
              onPress={() => {
                pleasePlay(songId, props.songs, "next");
              }}
            >
              <Ionicons
                name="play-circle-outline"
                size={60}
                color={palette.white}
                style={{ marginRight: -5 }}
              />
            </TouchableOpacity>
          </View>

          <FlatList
            contentContainerStyle={{
              flexGrow: 1,
              paddingBottom: SECOND_BREAKPOINT - 50,
            }}
            ref={songsToPlayRef}
            data={props.songs}
            extraData={{
              extraDataForMobX: JSON.stringify(props.songs) + songId,
            }}
            keyExtractor={(item) => `item-${item.id}`}
            ListEmptyComponent={
              <View
                style={{
                  justifyContent: "center",
                  flexGrow: 1,
                }}
              >
                <Text
                  preset="fieldLabel"
                  style={{
                    textAlign: "center",
                    color: color.text,
                    paddingHorizontal: spacing[4],
                  }}
                  tx="setlist.empty"
                />
              </View>
            }
            renderItem={({ item, index }) => {
              if (songsToPlayRef.current && songId === item.id) {
                try {
                  songsToPlayRef.current.scrollToIndex({
                    index,
                    animated: true,
                  });
                } catch (e) {
                  console.log(e);
                }
              }

              return (
                <TouchableOpacity
                  onPress={() => eventStore.sendSongToPlay(item.id)}
                  style={{
                    ...SONG,
                    flexDirection: "row",
                    backgroundColor:
                      index % 2
                        ? color.transparentBlackLight
                        : color.transparentBlackLighter,
                  }}
                >
                  <Text
                    text={`${index + 1}. ${item.title}`}
                    style={songId === item.id ? BOLD : null}
                  />
                  <Text
                    style={{ marginTop: -spacing[2], marginLeft: spacing[1] }}
                    text={songId === item.id ? " 🔈" : ""}
                  />
                </TouchableOpacity>
              );
            }}
          />
        </BottomSheet>

        <FloatingAction
          actions={[
            {
              text: translate("setlist.singer"),
              icon: (
                <MaterialCommunityIcons
                  name="microphone-variant"
                  size={25}
                  color={color.text}
                />
              ),
              color: color.transparentLight,
              name: Profile.SINGER,
            },
            {
              text: translate("setlist.guitar"),
              icon: (
                <MaterialCommunityIcons
                  name="guitar-electric"
                  size={25}
                  color={color.text}
                />
              ),
              color: color.transparentLight,
              name: Profile.GUITAR,
            },
          ]}
          distanceToEdge={{
            horizontal: 22,
            vertical: 100,
          }}
          visible={!!currentSong?.tab || !!currentSong?.guitar}
          onPressItem={(name: Profile) => setProfile(name)}
          color={color.transparentLight}
          floatingIcon={
            profile === Profile.SINGER ? (
              <MaterialCommunityIcons
                name="microphone-variant"
                size={30}
                color={color.text}
              />
            ) : (
              <MaterialCommunityIcons
                name="guitar-electric"
                size={30}
                color={color.text}
              />
            )
          }
        />
      </View>
    </View>
  );
});

function getSong(songId, songs): Song | null {
  const song = songs.find((s) => s.id === songId);

  return song || null;
}
