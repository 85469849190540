import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { UserStoreModel } from "../user-store/user-store";
import { SetlistStoreModel } from "../setlist-store/setlist-store";
import { SongStoreModel } from "../song-store/song-store";
import { BandStoreModel } from "../band-store/band-store";
import { EventStoreModel } from "../event-store/event-store";
import { GlobalStoreModel } from "../global-store/global-store";

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  globalStore: types.optional(GlobalStoreModel, {} as any),
  userStore: types.optional(UserStoreModel, {} as any),
  setlistStore: types.optional(SetlistStoreModel, {} as any),
  songStore: types.optional(SongStoreModel, {} as any),
  bandStore: types.optional(BandStoreModel, {} as any),
  eventStore: types.optional(EventStoreModel, {} as any),
});

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
