import { Api } from "../services/api";
import io from "socket.io-client";

const { API_URL } = require("../config/env");

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    this.api = new Api();
  }

  async setup() {
    await this.api.setup();
  }

  async setupSocket(accessToken: string) {
    this.socket = io.connect(API_URL, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      },
    });
  }

  /**
   * Our api.
   */
  api: Api;

  socket: SocketIOClient.Socket;
}
