import React from "react";
import { TextField, TextFieldProps } from "../text-field/text-field";
import { INPUT } from "../../theme/view-style";

export const BrandInput = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      inputStyle={[INPUT, props.inputStyle]}
      returnKeyType="go"
    />
  );
};
