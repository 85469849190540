import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { handleGeneralApiProblem } from "./api-problem";
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config";
import * as Types from "./api.types";
import jwtDecode from "jwt-decode";
import { JwtUserPayload } from "../../../../api/src/infrastructure/auth/jwt.strategy";
import { showMessage } from "react-native-flash-message";
import { translate } from "../../i18n";

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    });
  }

  async login(
    username: string,
    password: string,
  ): Promise<Types.GetUserResult> {
    const response: ApiResponse<any> = await this.apisauce.post("/login", {
      username,
      password,
    });

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return this.getUserResponse(response.data.access_token);
  }

  async signup(
    username: string,
    password: string,
  ): Promise<Types.GetSignupResult> {
    const response: ApiResponse<any> = await this.apisauce.post("/signup", {
      username,
      password,
    });

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    showMessage({
      message: translate("signupScreen.success"),
      type: "success",
    });
    return { kind: "ok" };
  }

  async createBand(token: string, name: string): Promise<Types.PostBandResult> {
    const response: ApiResponse<any> = await this.apisauce.post(
      "/bands",
      {
        name,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: "ok", band: { id: response.data.id } };
  }

  async joinBand(
    token: string,
    inviteToken: string,
  ): Promise<Types.PostBandResult> {
    const response: ApiResponse<any> = await this.apisauce.post(
      "/bands/join",
      {
        inviteToken,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: "ok", band: { id: response.data.id } };
  }

  async updateBand(
    token: string,
    { name }: { name: string },
  ): Promise<Types.UpdateBandResult> {
    const response: ApiResponse<any> = await this.apisauce.put(
      "/bands",
      {
        name,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: "ok", band: { id: response.data.id } };
  }

  async getBand(token: string): Promise<Types.GetBandResult> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/band",
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: "ok",
      band: {
        id: response.data.id,
        name: response.data.name,
        inviteToken: response.data.inviteToken,
      },
    };
  }

  async refreshToken(token: string): Promise<Types.GetUserResult> {
    const response: ApiResponse<any> = await this.apisauce.post(
      "/refresh",
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return this.getUserResponse(response.data.access_token);
  }

  async createSong(
    token: string,
    {
      title,
      lyric,
      guitar,
      tab,
      duration,
      key,
      tags,
    }: {
      title: string;
      lyric: string;
      guitar: string;
      duration: number;
      tab: string | null;
      key: string | null;
      tags: string | null;
    },
  ): Promise<Types.PostSongResult> {
    const response: ApiResponse<any> = await this.apisauce.post(
      "/songs",
      {
        title,
        lyric,
        guitar,
        tab,
        duration,
        key,
        tags,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: "ok", song: { id: response.data.id } };
  }

  async updateSong(
    token: string,
    {
      id,
      title,
      lyric,
      guitar,
      tab,
      duration,
      key,
      tags,
    }: {
      id: number;
      title: string;
      lyric: string;
      guitar: string;
      duration: number;
      tab: string | null;
      key: string | null;
      tags: string | null;
    },
  ): Promise<Types.PostSongResult> {
    const response: ApiResponse<any> = await this.apisauce.put(
      `/songs/${id}`,
      {
        title,
        lyric,
        guitar,
        tab,
        duration,
        key,
        tags,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: "ok", song: { id: response.data.id } };
  }

  async deleteSong(token: string, id: number): Promise<Types.DeleteSongResult> {
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/songs/${id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: "ok" };
  }

  async getSongs(token: string): Promise<Types.GetSongsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/songs",
      {},
      { headers: { Authorization: `Bearer ${token}` } },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: "ok",
      songs: response.data,
    };
  }

  async createSetlist(
    token: string,
    { name, songIds }: { name: string; songIds: number[] },
  ): Promise<Types.PostSetlistResult> {
    const response: ApiResponse<any> = await this.apisauce.post(
      "/setlists",
      {
        name,
        songIds,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: "ok", setlist: { id: response.data.id } };
  }

  async updateSetlist(
    token: string,
    { id, name, songIds }: { id: number; name: string; songIds: number[] },
  ): Promise<Types.PostSetlistResult> {
    const response: ApiResponse<any> = await this.apisauce.put(
      `/setlists/${id}`,
      {
        name,
        songIds,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: "ok", setlist: { id: response.data.id } };
  }

  async deleteSetlist(
    token: string,
    id: number,
  ): Promise<Types.DeleteSetlistResult> {
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/setlists/${id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: "ok" };
  }

  async getSetlists(token: string): Promise<Types.GetSetlistsResult> {
    const response: ApiResponse<any> = await this.apisauce.get(
      "/setlists",
      {},
      { headers: { Authorization: `Bearer ${token}` } },
    );

    if (!response.ok) {
      const problem = handleGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: "ok",
      setlists: response.data,
    };
  }

  private getUserResponse(accessToken: string): Types.GetUserResult {
    try {
      const decoded = jwtDecode<JwtUserPayload>(accessToken);

      const resultUser: {
        payload: JwtUserPayload;
        accessToken: string;
      } = {
        payload: decoded,
        accessToken: accessToken,
      };
      return { kind: "ok", user: resultUser };
    } catch {
      return { kind: "bad-data" };
    }
  }
}
