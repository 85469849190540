import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { BrandScreen } from "../../components/brand-screen/brand-screen";
import { useStores } from "../../models";
import { RouteProp, useNavigation } from "@react-navigation/native";
import { BandParamList } from "../../navigation";
import { Setlist } from "./setlist";
import { MenuOption, MenuOptions } from "react-native-popup-menu";
import { StackNavigationProp } from "@react-navigation/stack";
import { translate } from "../../i18n";
import * as Print from "expo-print";
import { getSetlistHtml } from "../../services/get-setlist-html";
import { Alert } from "react-native";
import { getSongHtml } from "../../services/get-song-html";
import { Profile } from "./profile";

type Props = {
  route: RouteProp<BandParamList, "setlist">;
};

export const SetlistScreen = observer(function SetlistScreen(props: Props) {
  const navigation = useNavigation<
    StackNavigationProp<BandParamList, "setlist">
  >();
  const goBack = () => navigation.goBack();
  const [title, setTitle] = useState("");
  const { setlistStore, songStore, eventStore } = useStores();
  const songId = eventStore.songId;
  const currentSong = songStore.songs.find((song) => song.id === songId);
  const setlist = setlistStore.setlists.find(
    (s) => s.id === props.route.params.setlistId,
  );

  if (!setlist) {
    return null;
  }

  async function duplicate() {
    if (!setlist) {
      return;
    }

    Alert.alert(translate("setlistScreen.duplicate-setlist"), "", [
      {
        text: translate("common.no"),
      },
      {
        text: translate("common.yes"),
        onPress: async () => {
          const result = await setlistStore.createSetlist({
            name: `${setlist.name} (bis)`,
            songIds: setlist.songs.map((s) => s.id),
          });

          if (result.kind === "ok") {
            goBack();
          }
        },
      },
    ]);
  }

  return (
    <BrandScreen
      title={title}
      menu={
        <MenuOptions>
          <MenuOption
            onSelect={() =>
              navigation.navigate("setlist-update", {
                setlist: {
                  id: setlist.id,
                  name: setlist.name,
                  songIds: setlist.songs.map((s) => s.id),
                },
              })
            }
            text={translate("setlistScreen.update-setlist")}
          />
          <MenuOption
            onSelect={async () => await duplicate()}
            text={translate("setlistScreen.duplicate-setlist")}
          />
          {currentSong ? (
            <>
              <MenuOption
                onSelect={() =>
                  navigation.navigate("song-update", {
                    song: currentSong,
                  })
                }
                text={translate("setlistScreen.update-song")}
              />
              <MenuOption
                onSelect={async () => {
                  await Print.printAsync({
                    html: getSongHtml(currentSong, Profile.SINGER, "print"),
                  });
                }}
                text={translate("setlistScreen.print-song")}
              />
            </>
          ) : null}
          <MenuOption
            onSelect={async () => {
              await Print.printAsync({
                html: getSetlistHtml(setlist),
              });
            }}
            text={translate("setlistScreen.print-setlist")}
          />
        </MenuOptions>
      }
    >
      <Setlist
        name={setlist.name}
        songs={setlist.songs}
        onTitleChange={setTitle}
      />
    </BrandScreen>
  );
});
