import { Instance, types } from "mobx-state-tree";

export const SongModel = types
  .model("Song")
  .props({
    id: types.identifierNumber,
    title: types.string,
    lyric: types.string,
    guitar: types.string,
    tab: types.maybeNull(types.string),
    duration: types.number,
    key: types.maybeNull(types.string),
    tags: types.maybeNull(types.string),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type SongType = Instance<typeof SongModel>;

export interface Song extends SongType {}
