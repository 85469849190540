import React, { FC } from "react";
import { presets } from "./wallpaper.presets";
import { WallpaperProps } from "./wallpaper.props";
import { LinearGradient } from "expo-linear-gradient";
import { color } from "../../theme";
import { ROUNDED } from "../../theme/view-style";

/**
 * For your text displaying needs.
 *
 * This component is a HOC over the built-in React Native one.
 */
export const WallpaperPrimary: FC<any> = function WallpaperPrimary(
  props: WallpaperProps,
) {
  // grab the props
  const { preset = "stretch", style: styleOverride } = props;

  // assemble the style
  const presetToUse = presets[preset] || presets.stretch;
  const styles = [presetToUse, styleOverride];

  return (
    <LinearGradient
      colors={[color.primary, color.primaryDarker]}
      style={[styles, ROUNDED]}
      start={[0, 1]}
      end={[1, 0]}
    />
  );
};
