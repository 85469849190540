// This is the first file that ReactNative will run when it starts up.
//
// We jump out of here immediately and into our main entry point instead.
//
// It is possible to have React Native load our main module first, but we'd have to
// change that in both AppDelegate.m and MainApplication.java.  This would have the
// side effect of breaking other tooling like mobile-center and react-native-rename.
//
// It's easier just to leave it here.
import App from "./app/app.tsx";
import { AppRegistry } from "react-native";
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import * as Sentry from "sentry-expo";

Sentry.init({
  dsn:
    "https://ca43f14588934e00865621cb9eaf72b6@o1268784.ingest.sentry.io/6456709",
  enableInExpoDevelopment: true,
});

AppRegistry.registerComponent("Setlist", () => App);
export default App;

serviceWorkerRegistration.register();
