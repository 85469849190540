import { TextStyle, ViewStyle } from "react-native";
import { color } from "./color";
import { spacing } from "./spacing";
import { typography } from "./typography";

export const CONTAINER: ViewStyle = {
  backgroundColor: color.transparent,
  paddingHorizontal: spacing[4],
};

export const CONTAINER_FULL: ViewStyle = {
  ...CONTAINER,
  flex: 1,
  marginTop: spacing[3],
  display: "flex",
  alignContent: "space-around",
};

export const ROUNDED: TextStyle = {
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[5],
  borderRadius: 10,
};

export const BOLD: TextStyle = { fontWeight: "bold" };

export const EMPHASE_TEXT: TextStyle = {
  ...BOLD,
  fontSize: 12,
  lineHeight: 15,
  textAlign: "center",
  letterSpacing: 1.5,
};

export const TITLE: TextStyle = {
  ...BOLD,
  fontSize: 28,
  lineHeight: 38,
  textAlign: "center",
  marginBottom: spacing[5],
};

export const INPUT: TextStyle = {
  marginTop: spacing[1],
  marginBottom: spacing[2],
  paddingHorizontal: spacing[2],
  borderRadius: 5,
  fontFamily: typography.primary,
  color: color.darkText,
  minHeight: 44,
  fontSize: 16,
  backgroundColor: color.palette.white,
};

export const SONG: TextStyle = {
  paddingHorizontal: spacing[4],
  paddingVertical: 9,
};

export const TAG: ViewStyle = {
  backgroundColor: color.text,
  marginLeft: spacing[1],
  paddingVertical: spacing[1],
  borderRadius: 5,
};

export const TAG_TEXT: TextStyle = {
  ...EMPHASE_TEXT,
  color: color.background,
  paddingHorizontal: spacing[2],
};
