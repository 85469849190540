import * as React from "react";
import { PropsWithChildren, useEffect } from "react";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { PrimaryParamList } from "../../navigation";
import { View, ViewStyle } from "react-native";
import { SONG } from "../../theme/view-style";
import { translate } from "../../i18n";
import { observer } from "mobx-react-lite";
import { useStores } from "../../models";
import { palette } from "../../theme/palette";
import Ionicons from "@expo/vector-icons/Ionicons";

const DIVIDER: ViewStyle = {
  marginVertical: 5,
  marginHorizontal: 2,
  borderBottomWidth: 1,
  borderColor: "#ccc",
};

interface BrandMenuProps {
  menu?: Element;
  title?: string;
}

export const useBrandMenu = function BrandMenu(props: BrandMenuProps) {
  const { title, menu } = props;
  const navigation = useNavigation<
    StackNavigationProp<PrimaryParamList, "intro" | "band">
  >();

  useEffect(() => {
    navigation.setOptions({
      headerRight: function headerRight() {
        return <HamburgerMenu menu={menu} />;
      },
    });
  }, [menu]);

  useEffect(() => {
    if (title) {
      navigation.setOptions({
        title,
      });
    }
  }, [title]);
};

export const HamburgerMenu = observer(function HamburgerMenu(
  props: PropsWithChildren<Pick<BrandMenuProps, "menu">>,
) {
  const { menu } = props;
  const navigation = useNavigation<
    StackNavigationProp<PrimaryParamList, "intro" | "band">
  >();
  const {
    userStore,
    songStore,
    setlistStore,
    bandStore,
    eventStore,
  } = useStores();

  return (
    <Menu renderer={renderers.ContextMenu}>
      <MenuTrigger>
        <Ionicons name="menu-outline" size={30} color={palette.white} />
      </MenuTrigger>
      <MenuOptions
        customStyles={{
          optionWrapper: SONG,
        }}
      >
        {menu}

        {menu ? <View style={DIVIDER} /> : null}

        <MenuOption
          onSelect={() =>
            userStore.isLoggedIn
              ? navigation.navigate("band", { screen: "setlists" })
              : navigation.navigate("intro", { screen: "welcome" })
          }
          text={translate("menu.home")}
        />
        {userStore.isLoggedIn ? (
          <>
            <MenuOption
              onSelect={() =>
                navigation.navigate("band", { screen: "settings" })
              }
              text={translate("menu.band-settings")}
            />
            <MenuOption
              onSelect={() =>
                navigation.navigate("band", {
                  screen: "band-join",
                  params: { inviteToken: undefined },
                })
              }
              text={translate("menu.band-join")}
            />
            <MenuOption
              onSelect={() => {
                eventStore.clear();
                songStore.clear();
                setlistStore.clear();
                userStore.clear();
                bandStore.clear();
              }}
              text={translate("menu.logout")}
            />
          </>
        ) : null}
      </MenuOptions>
    </Menu>
  );
});
