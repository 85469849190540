import {
  applySnapshot,
  getParent,
  Instance,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import { withEnvironment } from "../extensions/with-environment";
import * as Types from "../../services/api/api.types";
import { SetlistModel } from "../setlist/setlist";
import { RootStore } from "../root-store/root-store";

export const SetlistStoreModel = types
  .model("SetlistStore")
  .props({
    setlists: types.optional(types.array(SetlistModel), []),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .actions((self) => ({
    clear: function () {
      applySnapshot(self, {});
    },
    fetchSetlists: async function (): Promise<void> {
      const userStore = getParent<RootStore>(self).userStore;
      if (!userStore.currentUser) {
        return;
      }

      const response = await self.environment.api.getSetlists(
        userStore.currentUser.accessToken,
      );

      if (response.kind === "ok") {
        this._setSetlists(response.setlists);
      }
    },
    createSetlist: async function ({
      name,
      songIds,
    }: {
      name: string;
      songIds: number[];
    }): Promise<Types.PostSetlistResult> {
      const userStore = getParent<RootStore>(self).userStore;
      if (!userStore.currentUser) {
        return { kind: "unauthorized" };
      }

      return await self.environment.api.createSetlist(
        userStore.currentUser.accessToken,
        { name, songIds },
      );
    },
    updateSetlist: async function ({
      id,
      name,
      songIds,
    }: {
      id: number;
      name: string;
      songIds: number[];
    }): Promise<Types.PostSetlistResult> {
      const userStore = getParent<RootStore>(self).userStore;
      if (!userStore.currentUser) {
        return { kind: "unauthorized" };
      }

      return await self.environment.api.updateSetlist(
        userStore.currentUser.accessToken,
        { id, name, songIds },
      );
    },
    deleteSetlist: async function (
      id: number,
    ): Promise<Types.DeleteSetlistResult> {
      const userStore = getParent<RootStore>(self).userStore;
      if (!userStore.currentUser) {
        return { kind: "unauthorized" };
      }

      return await self.environment.api.deleteSetlist(
        userStore.currentUser.accessToken,
        id,
      );
    },
    _setSetlists(setlists) {
      self.setlists = setlists;
    },
  }));

type SetlistStoreType = Instance<typeof SetlistStoreModel>;

export interface SetlistStore extends SetlistStoreType {}

type SetlistStoreSnapshotType = SnapshotOut<typeof SetlistStoreModel>;

export interface SetlistStoreSnapshot extends SetlistStoreSnapshotType {}

export const createSetlistStoreDefaultModel = () =>
  types.optional(SetlistStoreModel, {});
