import * as React from "react";
import { PropsWithChildren } from "react";
import { SafeAreaView, ViewStyle } from "react-native";
import { observer } from "mobx-react-lite";

const FOOTER: ViewStyle = {
  marginBottom: 10,
};

export interface ScreenFooterProps {
  style?: ViewStyle;
}

export const ScreenFooter = observer(function ScreenFooter(
  props: PropsWithChildren<ScreenFooterProps>,
) {
  const { children, style } = props;

  return (
    <SafeAreaView style={{ ...FOOTER, ...style }}>{children}</SafeAreaView>
  );
});
