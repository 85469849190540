import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { BrandScreen } from "../../components/brand-screen/brand-screen";
import { useStores } from "../../models";
import { MenuOption, MenuOptions } from "react-native-popup-menu";
import { StackScreenProps } from "@react-navigation/stack";
import { BandParamList } from "../../navigation";
import { Text } from "../../components";
import { SONG } from "../../theme/view-style";
import { FlatList, RefreshControl, View } from "react-native";
import { color, spacing } from "../../theme";
import { ScreenFooter } from "../../components/screen-footer/screen-footer";
import { ScreenButton } from "../../components/screen-button/screen-button";
import { translate } from "../../i18n";
import { getDuration } from "./get-total-duration";
import { Song } from "../../models/song/song";

export const SetlistsScreen: FC<
  StackScreenProps<BandParamList, "setlists">
> = observer(function SetlistsScreen({ navigation }) {
  const { setlistStore, songStore, bandStore, eventStore } = useStores();
  const [isFetching, setIsFetching] = useState(false);

  return (
    <BrandScreen
      title={bandStore.name}
      menu={
        <MenuOptions>
          <MenuOption
            onSelect={() => navigation.navigate("song-create")}
            text={translate("setlistsScreen.create-song")}
          />
          <MenuOption
            onSelect={() => navigation.navigate("setlist-create")}
            text={translate("setlistsScreen.create-setlist")}
            disabled={songStore.songs.length === 0}
          />
        </MenuOptions>
      }
    >
      <FlatList
        contentContainerStyle={{ flexGrow: 1 }}
        data={setlistStore.setlists}
        extraData={{
          extraDataForMobX: JSON.stringify(setlistStore.setlists),
        }}
        refreshControl={
          <RefreshControl
            onRefresh={() => {
              setIsFetching(true);
              eventStore.fetchAll().then(() => setIsFetching(false));
            }}
            refreshing={isFetching}
            tintColor={color.text}
          />
        }
        keyExtractor={(item) => `item-${item.id}`}
        ListEmptyComponent={
          <View
            style={{
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <Text
              preset="fieldLabel"
              style={{
                textAlign: "center",
                paddingHorizontal: spacing[4],
              }}
              tx="setlistsScreen.empty"
            />
          </View>
        }
        renderItem={({ item, index }) => (
          <Text
            style={{
              ...SONG,
              backgroundColor:
                index % 2 ? color.background : color.backgroundLighter,
            }}
            text={`${item.name} - ${getDuration(item.songs as Song[])}`}
            onPress={() =>
              navigation.navigate("setlist", { setlistId: item.id })
            }
          />
        )}
      />

      <ScreenFooter>
        <ScreenButton
          tx="setlistsScreen.play-all"
          disabled={songStore.songs.length === 0}
          onPress={() => navigation.navigate("songs")}
        />
      </ScreenFooter>
    </BrandScreen>
  );
});
