import { applySnapshot, Instance, SnapshotOut, types } from "mobx-state-tree";
import { withEnvironment } from "../extensions/with-environment";
import { UserModel } from "../user/user";
import * as Types from "../../services/api/api.types";

export const UserStoreModel = types
  .model("UserStore")
  .props({
    currentUser: types.maybeNull(UserModel),
    isMenuOpened: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get isLoggedIn(): boolean {
      return !!self?.currentUser?.accessToken;
    },
    get isReadyToPlay(): boolean {
      return this.isLoggedIn && !!self.currentUser?.bandId;
    },
  }))
  .actions((self) => ({
    clear: function () {
      applySnapshot(self, {});
    },
    signup: async function (
      username: string,
      password: string,
    ): Promise<Types.GetSignupResult> {
      return await self.environment.api.signup(username, password);
    },
    login: async function (
      username: string,
      password: string,
    ): Promise<Types.GetUserResult> {
      const response = await self.environment.api.login(username, password);

      if (response.kind === "ok") {
        const user = UserModel.create({
          id: response.user.payload.sub,
          bandId: response.user.payload.bandId,
          username: response.user.payload.username,
          name: response.user.payload.name,
          accessToken: response.user.accessToken,
        });

        this._setCurrentUser(user);
      }

      return response;
    },
    toggleMenu: function () {
      self.isMenuOpened = !self.isMenuOpened;
    },
    _setCurrentUser(user) {
      self.currentUser = user;
    },
  }));

type UserStoreType = Instance<typeof UserStoreModel>;

export interface UserStore extends UserStoreType {}

type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>;

export interface UserStoreSnapshot extends UserStoreSnapshotType {}

export const createUserStoreDefaultModel = () =>
  types.optional(UserStoreModel, {});
