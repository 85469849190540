import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { HeaderText } from "../../components/header-text/header-text";
import { BrandScreen } from "../../components/brand-screen/brand-screen";
import { ScreenButton } from "../../components/screen-button/screen-button";
import { BrandInput } from "../../components/brand-input/brand-input";
import { useStores } from "../../models";
import { formValidateField } from "../../services/form-validate/form-validate-field";
import { hasErrors } from "../../services/form-validate/has-errors";
import { showMessage } from "react-native-flash-message";
import { displayErrors } from "../../services/form-validate/display-errors";
import { View } from "react-native";
import { CONTAINER_FULL } from "../../theme/view-style";
import { spacing } from "../../theme";
import { PrimaryParamList } from "../../navigation";
import { StackScreenProps } from "@react-navigation/stack";

interface BandForm {
  bandName: string;
  inviteToken: string;
  errors: any;
}

function getInitialState(): BandForm {
  return {
    bandName: "",
    inviteToken: "",
    errors: {},
  };
}

export const BandCreateScreen: FC<
  StackScreenProps<PrimaryParamList, "intro">
> = observer(function BandCreateScreen() {
  const { userStore, bandStore } = useStores();
  const [state, setState] = useState(getInitialState());

  function handleChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  async function create() {
    if (!userStore.currentUser) {
      return;
    }

    const errors = Object.assign(
      {},
      ...["bandName"].map((field) => formValidateField(field, state[field])),
    );

    setState({
      ...state,
      errors,
    });

    if (hasErrors(errors)) {
      showMessage({
        message: displayErrors(errors).join("\n"),
        type: "danger",
      });
    } else {
      const result = await bandStore.createBand(state.bandName);

      if (result.kind === "ok") {
        await userStore.currentUser.refreshToken();
      }
    }
  }

  async function join() {
    if (!userStore.currentUser) {
      return;
    }

    const errors = Object.assign(
      {},
      ...["inviteToken"].map((field) => formValidateField(field, state[field])),
    );

    setState({
      ...state,
      errors,
    });

    if (hasErrors(errors)) {
      showMessage({
        message: displayErrors(errors).join("\n"),
        type: "danger",
      });
    } else {
      const result = await bandStore.joinBand(state.inviteToken);

      if (result.kind === "ok") {
        await userStore.currentUser.refreshToken();
        bandStore.fetchBand();
      }
    }
  }

  return (
    <BrandScreen>
      <View style={{ ...CONTAINER_FULL, justifyContent: "space-between" }}>
        <View>
          <HeaderText
            headerTx="common.band"
            titleTx="bandCreateScreen.header"
          />

          <BrandInput
            labelTx="bandCreateScreen.name"
            value={state.bandName}
            autoCapitalize="none"
            onChangeText={(value) => handleChange("bandName", value)}
            autoCorrect={false}
          />

          <ScreenButton tx="bandCreateScreen.save" onPress={() => create()} />
        </View>

        <View style={{ marginBottom: spacing[8] }}>
          <HeaderText titleTx="bandCreateScreen.header-join" />

          <BrandInput
            labelTx="bandCreateScreen.inviteToken"
            value={state.inviteToken}
            autoCapitalize="none"
            onChangeText={(value) => handleChange("inviteToken", value)}
            autoCorrect={false}
          />

          <ScreenButton tx="bandCreateScreen.join" onPress={() => join()} />
        </View>
      </View>
    </BrandScreen>
  );
});
