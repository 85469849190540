import React from "react";
import { presets } from "./wallpaper.presets";
import { WallpaperProps } from "./wallpaper.props";
import { LinearGradient } from "expo-linear-gradient";

/**
 * For your text displaying needs.
 *
 * This component is a HOC over the built-in React Native one.
 */
export function Wallpaper(props: WallpaperProps) {
  // grab the props
  const { preset = "stretch", style: styleOverride } = props;

  // assemble the style
  const presetToUse = presets[preset] || presets.stretch;
  const styles = [presetToUse, styleOverride];

  return (
    <LinearGradient
      colors={["#434343", "#000000", "#000000"]}
      style={styles}
      start={[0, 1]}
      end={[1, 0]}
    />
  );
}
