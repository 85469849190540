import React, { FC, useCallback, useState } from "react";
import { RouteProp } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import { BrandScreen } from "../../components/brand-screen/brand-screen";
import { ScreenButton } from "../../components/screen-button/screen-button";
import { useStores } from "../../models";
import { StackScreenProps } from "@react-navigation/stack";
import { BandParamList } from "../../navigation";
import { Alert, FlatList, TouchableOpacity, View } from "react-native";
import { CONTAINER, SONG } from "../../theme/view-style";
import { ScreenFooter } from "../../components/screen-footer/screen-footer";
import { Setlist } from "../../models/setlist/setlist";
import { Text } from "../../components";
import DraggableFlatList, {
  RenderItemParams,
} from "react-native-draggable-flatlist";
import Ionicons from "@expo/vector-icons/Ionicons";
import { color, spacing } from "../../theme";
import { Song } from "../../models/song/song";
import { compact, sortBy } from "lodash";
import { formValidateField } from "../../services/form-validate/form-validate-field";
import { hasErrors } from "../../services/form-validate/has-errors";
import { showMessage } from "react-native-flash-message";
import { displayErrors } from "../../services/form-validate/display-errors";
import { translate } from "../../i18n";
import { BrandInput } from "../../components/brand-input/brand-input";
import { getDuration } from "./get-total-duration";
import { BrandTag } from "../songs/brand-tag";
import { palette } from "../../theme/palette";

export interface SetlistForm extends Omit<Setlist, "songs" | "id"> {
  id: number | null;
  songIds: number[];
  errors?: any;
}

type ManageSetlistScreenRouteProp =
  | RouteProp<BandParamList, "setlist-create">
  | RouteProp<BandParamList, "setlist-update">;

type Props = {
  route: ManageSetlistScreenRouteProp;
};

function getInitialState(): SetlistForm {
  return {
    id: null,
    name: "",
    songIds: [],
    errors: {},
  };
}

export const ManageSetlistScreen: FC<
  StackScreenProps<BandParamList, "setlist-create" | "setlist-update">
> = observer(function ManageSongScreen({
  navigation,
  route,
}: StackScreenProps<BandParamList, "setlist-create" | "setlist-update"> &
  Props) {
  const goBack = () => navigation.goBack();
  const { setlistStore, songStore } = useStores();
  const [state, setState] = useState(
    route.params ? route.params.setlist : getInitialState(),
  );

  function handleChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  const renderItem = useCallback(
    ({ item, index: _index, drag, isActive }: RenderItemParams<Song>) => {
      const index = _index || 0;
      const backgroundColor =
        index % 2 ? color.background : color.backgroundLighter;

      return (
        <TouchableOpacity
          style={{
            backgroundColor: isActive ? color.primary : backgroundColor,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onLongPress={drag}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
              paddingRight: spacing[4],
            }}
          >
            <Text style={{ ...SONG, flex: 1 }}>
              {index + 1}. {item.title} - {getDuration([item])}
            </Text>
            <BrandTag value={item.key} />
          </View>

          <Ionicons name="menu-outline" size={30} color={palette.white} />
        </TouchableOpacity>
      );
    },
    [],
  );

  async function deleteSetlist() {
    const id = state.id;
    if (id) {
      Alert.alert(translate("manageSetlistScreen.delete"), "", [
        {
          text: translate("common.no"),
        },
        {
          text: translate("common.yes"),
          onPress: async () => {
            const result = await setlistStore.deleteSetlist(id);

            if (result.kind === "ok") {
              navigation.reset({
                index: 0,
                routes: [{ name: "setlists" }],
              });
            }
          },
        },
      ]);
    }
  }

  async function save() {
    const errors = Object.assign(
      {},
      ...["name"].map((field) => formValidateField(field, state[field])),
    );

    setState({
      ...state,
      errors,
    });

    if (hasErrors(errors)) {
      showMessage({
        message: displayErrors(errors).join("\n"),
        type: "danger",
      });
    } else {
      let result;
      if (state.id) {
        result = await setlistStore.updateSetlist({
          ...state,
          id: state.id as number,
        });
      } else {
        result = await setlistStore.createSetlist(state);
      }

      if (result.kind === "ok") {
        goBack();
      }
    }
  }

  return (
    <BrandScreen>
      <View style={{ ...CONTAINER, flex: 1 }}>
        <DraggableFlatList
          ListHeaderComponent={
            <>
              <BrandInput
                labelTx="manageSetlistScreen.name"
                value={state.name}
                onChangeText={(value) => handleChange("name", value)}
              />

              <Text
                preset="fieldLabel"
                tx="manageSetlistScreen.selected-songs"
                style={{ marginTop: spacing[6], marginBottom: spacing[1] }}
              />
              <Text
                preset="fieldLabel"
                text={`${translate(
                  "manageSetlistScreen.duration",
                )} ${getDuration(
                  compact(
                    state.songIds.map((songId) =>
                      songStore.songs.find((song) => song.id === songId),
                    ),
                  ),
                )}`}
                style={{ marginBottom: spacing[1] }}
              />
            </>
          }
          data={state.songIds}
          renderItem={({ item, index, drag, isActive }) => {
            const song = songStore.songs.find((song) => song.id === item);

            if (song) {
              return renderItem({ item: song, index, drag, isActive });
            }

            return null;
          }}
          keyExtractor={(item) => `draggable-item-${item}`}
          onDragEnd={({ data }) => handleChange("songIds", data)}
          ListFooterComponent={
            <>
              <Text
                preset="fieldLabel"
                tx="manageSetlistScreen.available-songs"
                style={{ marginTop: spacing[6], marginBottom: spacing[1] }}
              />
              <FlatList
                data={sortBy(songStore.songs, ["title"])}
                keyExtractor={(item) => `item-${item.id}`}
                extraData={{
                  extraDataForMobX:
                    JSON.stringify(songStore.songs) +
                    JSON.stringify(state.songIds),
                }}
                initialNumToRender={songStore.songs.length}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    onPress={() => {
                      if (state.songIds.includes(item.id)) {
                        handleChange(
                          "songIds",
                          state.songIds.filter((i) => i !== item.id),
                        );
                      } else {
                        handleChange("songIds", state.songIds.concat(item.id));
                      }
                    }}
                  >
                    <View
                      style={{
                        paddingRight: spacing[4],
                        flexDirection: "row",
                        alignItems: "center",
                        backgroundColor: state.songIds.includes(item.id)
                          ? color.primary
                          : color.transparent,
                      }}
                    >
                      <Text
                        style={{ ...SONG, flex: 1 }}
                        text={`${item.title} - ${getDuration([item])}`}
                      />
                      <BrandTag value={item.key} />
                    </View>
                  </TouchableOpacity>
                )}
              />
            </>
          }
        />
      </View>

      <ScreenFooter
        style={
          state.id
            ? {
                flexDirection: "row",
                justifyContent: "space-around",
              }
            : {}
        }
      >
        {state.id ? (
          <ScreenButton
            tx="manageSetlistScreen.delete"
            onPress={() => deleteSetlist()}
            textStyle={{ color: color.text }}
            style={{ backgroundColor: color.error }}
          />
        ) : null}
        <ScreenButton tx="manageSetlistScreen.save" onPress={() => save()} />
      </ScreenFooter>
    </BrandScreen>
  );
});
