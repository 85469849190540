import {
  applySnapshot,
  getParent,
  Instance,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import { withEnvironment } from "../extensions/with-environment";
import { RootStore } from "../root-store/root-store";
import { debounce } from "lodash";

export const EventStoreModel = types
  .model("EventStore")
  .props({
    songId: types.optional(types.number, 0),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .actions((self) => ({
    init: function (accessToken: string) {
      self.environment.setupSocket(accessToken);

      self.environment.socket?.on("refresh-band", () => {
        this.fetchAll();
      });

      self.environment.socket?.on("play", (message) => {
        this._setSongId(message.songId);
      });
    },
    fetchAll: function () {
      const { bandStore, setlistStore, songStore } = getParent<RootStore>(self);

      return Promise.all([
        bandStore.fetchBand(),
        setlistStore.fetchSetlists(),
        songStore.fetchSongs(),
      ]);
    },
    sendSongToPlay: function (songId: number) {
      this._setSongId(songId);
      this._emitSongToPlay(songId);
    },
    clear: function () {
      self.environment.socket?.close();
      applySnapshot(self, {});
    },
    _setSongId(songId: number) {
      self.songId = songId;
    },
    _emitSongToPlay: debounce(function (songId: number) {
      self.environment.socket?.emit("please-play", { songId });
    }, 500),
  }));

type EventStoreType = Instance<typeof EventStoreModel>;

export interface EventStore extends EventStoreType {}

type EventStoreSnapshotType = SnapshotOut<typeof EventStoreModel>;

export interface EventStoreSnapshot extends EventStoreSnapshotType {}

export const createEventStoreDefaultModel = () =>
  types.optional(EventStoreModel, {});
