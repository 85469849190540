/**
 * Ignore some yellowbox warnings. Some of these are for deprecated functions
 * that we haven't gotten around to replacing yet.
 */
import { LogBox, Platform } from "react-native";

if (Platform.OS !== "web") {
  // prettier-ignore
  LogBox.ignoreLogs([
    "Require cycle:",
  ]);
}
