import * as React from "react";
import { PropsWithChildren } from "react";
import { StatusBar, View, ViewStyle } from "react-native";
import { observer } from "mobx-react-lite";
import { Wallpaper } from "../wallpaper/wallpaper";
import { color, spacing } from "../../theme";
import { WallpaperHomepage } from "../wallpaper/wallpaper-homepage";
import { useBrandMenu } from "../brand-menu/brand-menu";

const FULL: ViewStyle = {
  flex: 1,
};

export interface BrandScreenProps {
  style?: ViewStyle;
  isSplash?: boolean;
  title?: string;
  menu?: Element;
}

export const BrandScreen = observer(function BrandScreen(
  props: PropsWithChildren<BrandScreenProps>,
) {
  const { children, style, menu, title } = props;
  const isSplash = props.isSplash === true;

  useBrandMenu({ title: title, menu: menu });

  return (
    <View
      style={{
        backgroundColor: color.background,
        ...FULL,
        ...style,
        paddingTop: isSplash ? 0 : spacing[2],
      }}
    >
      <StatusBar barStyle="light-content" />

      {isSplash ? (
        <WallpaperHomepage style={{ resizeMode: "cover" }} />
      ) : (
        <Wallpaper />
      )}

      {children}
    </View>
  );
});
