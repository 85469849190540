import { Instance, types } from "mobx-state-tree";
import { withEnvironment } from "../extensions/with-environment";
import * as Types from "../../services/api/api.types";
import { UserPayloadResult } from "../../services/api";

export const UserModel = types
  .model("User")
  .props({
    id: types.identifierNumber,
    name: types.string,
    username: types.string,
    accessToken: types.string,
    bandId: types.maybeNull(types.number),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .actions((self) => ({
    refreshToken: async function (): Promise<Types.GetUserResult> {
      const response = await self.environment.api.refreshToken(
        self.accessToken,
      );

      if (response.kind === "ok") {
        this._updateMe(response.user);
      }

      return response;
    },
    _updateMe(user: UserPayloadResult) {
      self.name = user.payload.name;
      self.bandId = user.payload.bandId || null;
      self.username = user.payload.username;
      self.accessToken = user.accessToken;
    },
  }));

type UserType = Instance<typeof UserModel>;

export interface User extends UserType {}
