import React, { FC, useState } from "react";
import { RouteProp } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import { BrandScreen } from "../../components/brand-screen/brand-screen";
import { ScreenButton } from "../../components/screen-button/screen-button";
import { BrandInput } from "../../components/brand-input/brand-input";
import { useStores } from "../../models";
import { StackScreenProps } from "@react-navigation/stack";
import { BandParamList } from "../../navigation";
import { ScrollView } from "react-native";
import { ScreenFooter } from "../../components/screen-footer/screen-footer";
import { formValidateField } from "../../services/form-validate/form-validate-field";
import { hasErrors } from "../../services/form-validate/has-errors";
import { showMessage } from "react-native-flash-message";
import { displayErrors } from "../../services/form-validate/display-errors";
import { CONTAINER } from "../../theme/view-style";

interface UserForm {
  inviteToken: string;
  errors?: any;
}

type BandJoinScreenRouteProp = RouteProp<BandParamList, "band-join">;

type Props = {
  route: BandJoinScreenRouteProp;
};

function getInitialState(inviteToken = ""): UserForm {
  return {
    inviteToken,
    errors: {},
  };
}

export const BandJoinScreen: FC<
  StackScreenProps<BandParamList, "band-join">
> = observer(function BandJoinScreen({
  navigation,
  route,
}: StackScreenProps<BandParamList, "band-join"> & Props) {
  const { bandStore, userStore } = useStores();
  const [state, setState] = useState(
    getInitialState(route.params?.inviteToken),
  );

  function handleChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  async function join() {
    if (!userStore.currentUser) {
      return;
    }

    const errors = Object.assign(
      {},
      ...["inviteToken"].map((field) => formValidateField(field, state[field])),
    );

    setState({
      ...state,
      errors,
    });

    if (hasErrors(errors)) {
      showMessage({
        message: displayErrors(errors).join("\n"),
        type: "danger",
      });
    } else {
      const result = await bandStore.joinBand(state.inviteToken);

      if (result.kind === "ok") {
        const res = await userStore.currentUser.refreshToken();

        if (res.kind === "ok") {
          navigation.reset({
            index: 0,
            routes: [{ name: "setlists" }],
          });
        }
      }
    }
  }

  return (
    <BrandScreen>
      <ScrollView style={CONTAINER}>
        <BrandInput
          labelTx="userSettingsScreen.inviteToken"
          value={state.inviteToken}
          onChangeText={(value) => handleChange("inviteToken", value)}
        />
      </ScrollView>

      <ScreenFooter>
        <ScreenButton tx="userSettingsScreen.join" onPress={() => join()} />
      </ScreenFooter>
    </BrandScreen>
  );
});
