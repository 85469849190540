import { Setlist } from "../models/setlist/setlist";

export function getSetlistHtml(setlist: Setlist) {
  return `
<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <style>
      * {
          color: black !important;
      }

      #lyric {
          font-size: 1.1rem;
          font-family: Helvetica, arial, sans-serif;
          line-height: 0.4;
      }

      #lyric-title {
          margin-top: 0;
          text-align: center;
          line-height: 1.5;
      }
  </style>
</head>
<body>
<div id="lyric">
  <h2 id="lyric-title">${setlist.name}</h2>${setlist.songs
    .map((s, index) => `<p>${index + 1}. ${s.title}<p>`)
    .join("")}
</div>
</body>
</html>`;
}
