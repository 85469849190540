export const palette = {
  darkBlack: "#000000",
  black: "#1d1d1d",
  blackLight: "#414141",
  white: "#ffffff",
  offWhite: "#e6e6e6",
  orange: "#f09819",
  orangeDarker: "#ff5858",
  lightGrey: "#939AA4",
  lighterGrey: "#CDD4DA",
  angry: "#dd3333",
};
