import React, { FC, useState } from "react";
import { RouteProp } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import { BrandScreen } from "../../components/brand-screen/brand-screen";
import { ScreenButton } from "../../components/screen-button/screen-button";
import { useStores } from "../../models";
import { StackScreenProps } from "@react-navigation/stack";
import { BandParamList } from "../../navigation";
import {
  Alert,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
} from "react-native";
import { Song } from "../../models/song/song";
import { color } from "../../theme";
import moment from "moment";
import { translate } from "../../i18n";
import { formValidateField } from "../../services/form-validate/form-validate-field";
import { hasErrors } from "../../services/form-validate/has-errors";
import { showMessage } from "react-native-flash-message";
import { displayErrors } from "../../services/form-validate/display-errors";
import { getDuration } from "../setlists/get-total-duration";
import { SingerForm } from "./singer-form";
import { GuitaristForm } from "./guitarist-form";
import { MainForm } from "./main-form";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { ScreenFooter } from "../../components/screen-footer/screen-footer";

export interface SongForm extends Omit<Song, "id"> {
  id: number | null;
  durationStr?: string;
  errors?: any;
}

type ManageSongScreenRouteProp =
  | RouteProp<BandParamList, "song-create">
  | RouteProp<BandParamList, "song-update">;

type Props = {
  route: ManageSongScreenRouteProp;
};

function getInitialState(): SongForm {
  return {
    id: null,
    title: "",
    tab: "",
    lyric: "",
    guitar: "",
    duration: 0,
    key: "",
    tags: "",
    durationStr: "",
    errors: {},
  };
}

const SCREEN_FOOTER_HEIGHT = 50;

export const ManageSongScreen: FC<
  StackScreenProps<BandParamList, "song-create" | "song-update">
> = observer(function ManageSongScreen({
  navigation,
  route,
}: StackScreenProps<BandParamList, "song-create" | "song-update"> & Props) {
  const goBack = () => navigation.goBack();
  const insets = useSafeAreaInsets();
  const { songStore } = useStores();
  const [state, setState] = useState(
    route.params
      ? {
          ...route.params.song,
          durationStr: getDuration([route.params.song]),
        }
      : getInitialState(),
  );

  function handleChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  async function deleteSong() {
    const id = state.id;
    if (id) {
      Alert.alert(translate("manageSongScreen.delete"), "", [
        {
          text: translate("common.no"),
        },
        {
          text: translate("common.yes"),
          onPress: async () => {
            const result = await songStore.deleteSong(id);

            if (result.kind === "ok") {
              goBack();
            }
          },
        },
      ]);
    }
  }

  async function save() {
    const errors = Object.assign(
      {},
      ...["title", "tab"].map((field) =>
        formValidateField(field, state[field]),
      ),
    );

    setState({
      ...state,
      errors,
    });

    if (hasErrors(errors)) {
      showMessage({
        message: displayErrors(errors).join("\n"),
        type: "danger",
      });
    } else {
      let result;
      const duration = moment
        .utc(state.durationStr, "mm:ss")
        .diff(moment.utc().startOf("day"), "seconds");

      if (state.id) {
        result = await songStore.updateSong({
          ...state,
          id: state.id as number,
          duration: duration || 0,
        });
      } else {
        result = await songStore.createSong({
          ...state,
          duration: duration || 0,
        });
      }

      if (result.kind === "ok") {
        goBack();
      }
    }
  }

  return (
    <BrandScreen>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        keyboardVerticalOffset={insets.bottom + SCREEN_FOOTER_HEIGHT}
        style={{ flex: 1 }}
      >
        <ScrollView>
          <MainForm state={state} handleChange={handleChange} />

          <SingerForm state={state} handleChange={handleChange} />

          <GuitaristForm state={state} handleChange={handleChange} />
        </ScrollView>

        <ScreenFooter
          style={
            state.id
              ? {
                  flexDirection: "row",
                  justifyContent: "space-around",
                }
              : {}
          }
        >
          {state.id ? (
            <ScreenButton
              tx="manageSongScreen.delete"
              onPress={() => deleteSong()}
              textStyle={{ color: color.text }}
              style={{ backgroundColor: color.error }}
            />
          ) : null}
          <ScreenButton tx="manageSongScreen.save" onPress={() => save()} />
        </ScreenFooter>
      </KeyboardAvoidingView>
    </BrandScreen>
  );
});
