import * as React from "react";
import { TextStyle, View, ViewStyle } from "react-native";
import { observer } from "mobx-react-lite";
import { color, spacing, typography } from "../../theme";
import { Button } from "../";
import { ButtonProps } from "../button/button.props";
import { BOLD, ROUNDED } from "../../theme/view-style";

const TEXT: TextStyle = {
  color: color.darkText,
  fontFamily: typography.primary,
};

const BUTTON: ViewStyle = {
  shadowColor: "#000",
  shadowOpacity: 0.2,
  shadowRadius: 1,
  backgroundColor: color.button,
};

const CONTINUE_DISABLED: ViewStyle = {
  ...ROUNDED,
  ...BUTTON,
  opacity: 0.5,
};

const CONTINUE: TextStyle = {
  ...ROUNDED,
  ...BUTTON,
};

const CONTINUE_TEXT: TextStyle = {
  ...TEXT,
  ...BOLD,
  letterSpacing: 2,
  fontSize: 14,
};

const FOOTER_CONTENT: ViewStyle = {
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[4],
};

interface ScreenButtonProps extends ButtonProps {
  visible?: boolean;
}

export function BrandButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      style={
        props.disabled
          ? [CONTINUE_DISABLED, props.style]
          : [CONTINUE, props.style]
      }
      textStyle={[CONTINUE_TEXT, props.textStyle]}
    />
  );
}

export const ScreenButton = observer(function ScreenButton(
  props: ScreenButtonProps,
) {
  if (props.visible === false) {
    return null;
  }

  return (
    <View style={FOOTER_CONTENT}>
      <BrandButton {...props} />
    </View>
  );
});
