import {
  applySnapshot,
  getParent,
  Instance,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import { withEnvironment } from "../extensions/with-environment";
import * as Types from "../../services/api/api.types";
import { SongModel } from "../song/song";
import { RootStore } from "../root-store/root-store";

export const SongStoreModel = types
  .model("SongStore")
  .props({
    songs: types.optional(types.array(SongModel), []),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .actions((self) => ({
    clear: function () {
      applySnapshot(self, {});
    },
    fetchSongs: async function (): Promise<void> {
      const userStore = getParent<RootStore>(self).userStore;

      const response = await self.environment.api.getSongs(
        userStore.currentUser!.accessToken,
      );

      if (response.kind === "ok") {
        this._setSongs(response.songs);
      }
    },
    createSong: async function ({
      title,
      lyric,
      guitar,
      tab,
      duration,
      key,
      tags,
    }: {
      title: string;
      lyric: string;
      guitar: string;
      duration: number;
      tab: string | null;
      key: string | null;
      tags: string | null;
    }): Promise<Types.PostSongResult> {
      const userStore = getParent<RootStore>(self).userStore;

      return await self.environment.api.createSong(
        userStore.currentUser!.accessToken,
        { title, lyric, guitar, tab, duration, key, tags },
      );
    },
    updateSong: async function ({
      id,
      title,
      lyric,
      guitar,
      tab,
      duration,
      key,
      tags,
    }: {
      id: number;
      title: string;
      lyric: string;
      guitar: string;
      duration: number;
      tab: string | null;
      key: string | null;
      tags: string | null;
    }): Promise<Types.PostSongResult> {
      const userStore = getParent<RootStore>(self).userStore;

      return await self.environment.api.updateSong(
        userStore.currentUser!.accessToken,
        { id, title, lyric, guitar, tab, duration, key, tags },
      );
    },
    deleteSong: async function (id: number): Promise<Types.DeleteSongResult> {
      const userStore = getParent<RootStore>(self).userStore;

      return await self.environment.api.deleteSong(
        userStore.currentUser!.accessToken,
        id,
      );
    },
    _setSongs(songs) {
      self.songs = songs;
    },
  }));

type SongStoreType = Instance<typeof SongStoreModel>;

export interface SongStore extends SongStoreType {}

type SongStoreSnapshotType = SnapshotOut<typeof SongStoreModel>;

export interface SongStoreSnapshot extends SongStoreSnapshotType {}

export const createSongStoreDefaultModel = () =>
  types.optional(SongStoreModel, {});
