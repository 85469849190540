import {
  applySnapshot,
  getParent,
  Instance,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import { withEnvironment } from "../extensions/with-environment";
import * as Types from "../../services/api/api.types";
import { RootStore } from "../root-store/root-store";

export const BandStoreModel = types
  .model("BandStore")
  .props({
    name: types.optional(types.string, ""),
    inviteToken: types.optional(types.string, ""),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .actions((self) => ({
    clear: function () {
      applySnapshot(self, {});
    },
    fetchBand: async function (): Promise<void> {
      const userStore = getParent<RootStore>(self).userStore;

      const response = await self.environment.api.getBand(
        userStore.currentUser!.accessToken,
      );

      if (response.kind === "ok") {
        this._setBand({
          name: response.band.name,
          inviteToken: response.band.inviteToken,
        });
      }
    },
    updateBand: async function ({
      name,
    }: {
      name: string;
    }): Promise<Types.UpdateBandResult> {
      const userStore = getParent<RootStore>(self).userStore;

      return await self.environment.api.updateBand(
        userStore.currentUser!.accessToken,
        { name },
      );
    },
    createBand: async function (name: string): Promise<Types.PostBandResult> {
      const userStore = getParent<RootStore>(self).userStore;

      return await self.environment.api.createBand(
        userStore.currentUser!.accessToken,
        name,
      );
    },
    joinBand: async function (
      inviteToken: string,
    ): Promise<Types.PostBandResult> {
      const userStore = getParent<RootStore>(self).userStore;

      return await self.environment.api.joinBand(
        userStore.currentUser!.accessToken,
        inviteToken,
      );
    },
    _setBand({ name, inviteToken }: { name: string; inviteToken: string }) {
      self.name = name;
      self.inviteToken = inviteToken;
    },
  }));

type BandStoreType = Instance<typeof BandStoreModel>;

export interface BandStore extends BandStoreType {}

type BandStoreSnapshotType = SnapshotOut<typeof BandStoreModel>;

export interface BandStoreSnapshot extends BandStoreSnapshotType {}

export const createBandStoreDefaultModel = () =>
  types.optional(BandStoreModel, {});
