import { Instance, types } from "mobx-state-tree";
import { SongModel } from "../song/song";

export const SetlistModel = types
  .model("Setlist")
  .props({
    id: types.identifierNumber,
    name: types.string,
    songs: types.optional(types.array(SongModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type SetlistType = Instance<typeof SetlistModel>;

export interface Setlist extends SetlistType {}
