import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { BrandScreen } from "../../components/brand-screen/brand-screen";
import { ScreenButton } from "../../components/screen-button/screen-button";
import { BrandInput } from "../../components/brand-input/brand-input";
import { useStores } from "../../models";
import { StackScreenProps } from "@react-navigation/stack";
import { BandParamList } from "../../navigation";
import { Platform, Pressable, ScrollView, Share, View } from "react-native";
import { ScreenFooter } from "../../components/screen-footer/screen-footer";
import { formValidateField } from "../../services/form-validate/form-validate-field";
import { hasErrors } from "../../services/form-validate/has-errors";
import { showMessage } from "react-native-flash-message";
import { displayErrors } from "../../services/form-validate/display-errors";
import { CONTAINER } from "../../theme/view-style";

interface BandForm {
  bandName: string;
  inviteToken: string;
  errors?: any;
}

function getInitialState({
  name,
  inviteToken,
}: {
  name: string;
  inviteToken: string;
}): BandForm {
  return {
    bandName: name,
    inviteToken: inviteToken,
    errors: {},
  };
}

export const BandUpdateScreen: FC<
  StackScreenProps<BandParamList, "settings">
> = observer(function BandUpdateScreen({ navigation }) {
  const goBack = () => navigation.goBack();
  const { bandStore } = useStores();
  const [state, setState] = useState(
    getInitialState({
      name: bandStore.name,
      inviteToken: bandStore.inviteToken,
    }),
  );
  const inviteUrl = `https://setlist.live/join?token=${state.inviteToken}`;

  function handleChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  async function save() {
    const errors = Object.assign(
      {},
      ...["bandName"].map((field) => formValidateField(field, state[field])),
    );

    setState({
      ...state,
      errors,
    });

    if (hasErrors(errors)) {
      showMessage({
        message: displayErrors(errors).join("\n"),
        type: "danger",
      });
    } else {
      const result = await bandStore.updateBand({ name: state.bandName });

      if (result.kind === "ok") {
        bandStore.fetchBand();
        goBack();
      }
    }
  }

  return (
    <BrandScreen>
      <ScrollView style={CONTAINER}>
        <BrandInput
          labelTx="bandUpdateScreen.name"
          value={state.bandName}
          onChangeText={(value) => handleChange("bandName", value)}
        />

        {Platform.OS === "web" ? (
          <BrandInput
            labelTx="bandUpdateScreen.invite-token"
            value={inviteUrl}
          />
        ) : (
          <Pressable
            onPress={async () => {
              await Share.share({
                message: inviteUrl,
              });
            }}
          >
            <View pointerEvents="none">
              <BrandInput
                labelTx="bandUpdateScreen.invite-token"
                value={inviteUrl}
              />
            </View>
          </Pressable>
        )}
      </ScrollView>

      <ScreenFooter>
        <ScreenButton tx="bandUpdateScreen.save" onPress={() => save()} />
      </ScreenFooter>
    </BrandScreen>
  );
});
