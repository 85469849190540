import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { BrandScreen } from "../../components/brand-screen/brand-screen";
import { ScreenFooter } from "../../components/screen-footer/screen-footer";
import { ScreenButton } from "../../components/screen-button/screen-button";
import { StackScreenProps } from "@react-navigation/stack";
import { PrimaryParamList } from "../../navigation";
import { HeaderText } from "../../components/header-text/header-text";
import { SafeAreaView, View } from "react-native";
import { CONTAINER_FULL } from "../../theme/view-style";
import { Text } from "../../components";
import { DEFAULT_API_CONFIG } from "../../services/api/api-config";
import { spacing } from "../../theme";
import Constants from "expo-constants";

export const WelcomeScreen: FC<
  StackScreenProps<PrimaryParamList, "intro">
> = observer(function WelcomeScreen({ navigation }) {
  return (
    <BrandScreen isSplash={true}>
      <SafeAreaView style={CONTAINER_FULL}>
        <HeaderText
          headerTx="welcomeScreen.poweredBy"
          titleText="SETLIST LIVE"
        />
      </SafeAreaView>

      <ScreenFooter>
        <ScreenButton
          tx="form.signup"
          onPress={() => navigation.navigate("intro", { screen: "signup" })}
        />
        <ScreenButton
          tx="form.login"
          onPress={() => navigation.navigate("intro", { screen: "login" })}
        />

        <View style={{ alignItems: "center", marginBottom: spacing[2] }}>
          <Text text={DEFAULT_API_CONFIG.url} preset="fieldLabel" />
          <Text preset="fieldLabel" text={Constants.manifest?.version || ""} />
        </View>
      </ScreenFooter>
    </BrandScreen>
  );
});
