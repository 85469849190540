function validPasswordValue(value) {
  if (value.length < 6) {
    return "errors.password";
  }

  return null;
}

function validUsernameValue(value) {
  if (value.length < 4) {
    return "errors.username";
  }

  return null;
}

function validBandNameValue(value) {
  if (value === "") {
    return "errors.band-name";
  }

  return null;
}

function validInviteTokenValue(value) {
  if (value === "") {
    return "errors.invite-token";
  }

  return null;
}

function validNameValue(value) {
  if (value === "") {
    return "errors.setlist-name";
  }

  return null;
}

function validTitleValue(value) {
  if (value === "") {
    return "errors.song-title";
  }

  return null;
}

function validTabValue(value) {
  if (value !== "") {
    if (!/^(www|http:|https:)+[^\s]+[\w]$/.test(value)) {
      return "errors.song-tab";
    }
  }

  return null;
}

const VALIDATORS = {
  password: validPasswordValue,
  username: validUsernameValue,
  bandName: validBandNameValue,
  inviteToken: validInviteTokenValue,
  name: validNameValue,
  title: validTitleValue,
  tab: validTabValue,
};

export function formValidateField(name, value) {
  // eslint-disable-next-line no-prototype-builtins
  if (VALIDATORS.hasOwnProperty(name)) {
    return { [name]: VALIDATORS[name](value) };
  }

  return { [name]: "errors.unknown" };
}
