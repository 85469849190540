import { Text } from "../text/text";
import React from "react";
import { Header } from "../header/header";
import { TextStyle } from "react-native";
import { spacing } from "../../theme";
import { HeaderProps } from "../header/header.props";
import { EMPHASE_TEXT, TITLE } from "../../theme/view-style";

const HEADER: TextStyle = {
  paddingTop: spacing[1],
  paddingBottom: spacing[2] - 1,
  paddingHorizontal: 0,
};

interface HeaderTextProps extends HeaderProps {
  text?: string;
  titleText?: string;
  titleTx?: string;
}

export const HeaderText = (props: HeaderTextProps) => {
  const { text, headerTx, titleText, titleTx } = props;
  return (
    <>
      {text || headerTx ? (
        <Header
          {...props}
          headerText={text}
          style={HEADER}
          titleStyle={EMPHASE_TEXT}
        />
      ) : null}
      {titleText || titleTx ? (
        <Text style={TITLE} preset="header" text={titleText} tx={titleTx} />
      ) : null}
    </>
  );
};
