import React, { useRef } from "react";
import { View } from "react-native";
import { CONTAINER } from "../../theme/view-style";
import { Text } from "../../components";
import { spacing } from "../../theme";
import {
  actions,
  RichEditor,
  RichToolbar,
} from "react-native-pell-rich-editor";
import { SongForm } from "./manage-song-screen";

export function SingerForm(props: {
  state: SongForm;
  handleChange: (name, value) => void;
}) {
  const { state, handleChange } = props;
  const rich = useRef(null);

  return (
    <View style={{ ...CONTAINER }}>
      <Text
        preset="fieldLabel"
        tx="manageSongScreen.lyric"
        style={{ marginTop: spacing[6], marginBottom: spacing[1] }}
      />
      <RichToolbar
        editor={rich}
        actions={[
          actions.keyboard,
          actions.setBold,
          actions.setItalic,
          actions.removeFormat,
          actions.insertLink,
        ]}
      />
      <RichEditor
        initialContentHTML={state.lyric}
        ref={rich}
        onChange={(value) => handleChange("lyric", value)}
        initialHeight={300}
      />
    </View>
  );
}
