import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { BrandScreen } from "../../components/brand-screen/brand-screen";
import { useStores } from "../../models";
import { Setlist } from "../setlists/setlist";
import { MenuOption, MenuOptions } from "react-native-popup-menu";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { BandParamList } from "../../navigation";
import { sortBy } from "lodash";
import { translate } from "../../i18n";
import * as Print from "expo-print";
import { getSongHtml } from "../../services/get-song-html";
import { Profile } from "../setlists/profile";

export const SongsScreen = observer(function SongsScreen() {
  const navigation = useNavigation<
    StackNavigationProp<BandParamList, "songs">
  >();
  const [title, setTitle] = useState("");
  const { songStore, eventStore } = useStores();
  const songId = eventStore.songId;
  const currentSong = songStore.songs.find((song) => song.id === songId);

  return (
    <BrandScreen
      title={title}
      menu={
        currentSong ? (
          <>
            <MenuOptions>
              <MenuOption
                onSelect={() =>
                  navigation.navigate("song-update", {
                    song: currentSong,
                  })
                }
                text={translate("setlistScreen.update-song")}
              />
            </MenuOptions>
            <MenuOption
              onSelect={async () => {
                await Print.printAsync({
                  html: getSongHtml(currentSong, Profile.SINGER, "print"),
                });
              }}
              text={translate("setlistScreen.print-song")}
            />
          </>
        ) : undefined
      }
    >
      <Setlist
        name={translate("common.all-songs")}
        songs={sortBy(songStore.songs, ["title"])}
        onTitleChange={setTitle}
      />
    </BrandScreen>
  );
});
