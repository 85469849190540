import moment from "moment";
import { Song } from "../../models/song/song";
import { SongForm } from "../songs/manage-song-screen";

export function getDuration(songs: (Song | SongForm)[]) {
  const totalDuration = songs.reduce((sum, song) => sum + song.duration, 0);
  return moment
    .utc()
    .startOf("day")
    .seconds(totalDuration)
    .format("HH:mm:ss")
    .replace(/^0(?:0:0?)?/, "");
}
